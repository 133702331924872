import type { MutationTree } from '@utils/esmParser'
import type { IAxeptioState } from '@application/store/axeptio/interface'
import { axeptioTypes } from '@application/store/axeptio/constants'

const axeptioMutations: MutationTree<IAxeptioState> = {
  [axeptioTypes.SET_READY]: (state: IAxeptioState, ready: boolean) => {
    state.ready = ready
  },
  [axeptioTypes.SET_CONSENTS]: (state: IAxeptioState, consents: Record<string, boolean>) => {
    state.consents = consents
  }
}

export default axeptioMutations

import { defineNuxtPlugin } from '#imports'
import type { Router, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useAuthentication } from '@contexts/auth/application/composables/useAuthentication'
import Logger from '@utils/logger/logger'
import type { NuxtApp } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp: NuxtApp) => {
  const authentication = useAuthentication()
  const router: Router = nuxtApp.$router as Router

  router.beforeEach(async (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
    next()
    // check every route changing if the user is authenticated (logged and valid token)
    try {
      const isUserAuthenticated = await authentication.isUserAuthenticated()
      authentication.setIsAuthenticatedWithValidToken(isUserAuthenticated)
    } catch (err: unknown) {
      Logger.error('Authentication error', err)
    }
  })
})

import type { ActionContext, ActionTree } from '@utils/esmParser'
import type { IUserState } from '@application/store/user/interface'
import type { IRootState } from '@application/store/state.interface'
import { EUserStatus, USER_ACTIONS } from '@application/store/user/constants'
import type { User } from '@contexts/user'
import { getAnonymousUser, getCurrentUser, saveAnonymousUser, UserSegments } from '@contexts/user'
import { useNuxtApp } from '#imports'
import { cookieTypes, useCookie } from '@/application/composables/cookie'

const userActions: ActionTree<IUserState, IRootState> = {
  async initUser(context: ActionContext<IUserState, IRootState>) {
    const { $sentry: sentry } = useNuxtApp()
    const cookie = useCookie(cookieTypes.USER_NOT_CONNECTED)
    const notConnectedUser = await getAnonymousUser.execute(cookie)
    context.commit(USER_ACTIONS.SET_CURRENT_USER, notConnectedUser)
    context.dispatch('setUserConnectionStatus', notConnectedUser)

    if (process.client) {
      sentry.setUser({ id: notConnectedUser.customUserId })
    }
    return context.state
  },
  async saveNotConnectedUser(context: ActionContext<IUserState, IRootState>, user: IUserState) {
    const { $sentry: sentry } = useNuxtApp()
    if (user.current) {
      const cookie = useCookie(cookieTypes.USER_NOT_CONNECTED)
      await saveAnonymousUser.execute(user.current, cookie)
    }

    if (process.client) {
      sentry.setUser({ id: user.current?.customUserId })
    }
    return context.state
  },
  async setCurrentUser(context: ActionContext<IUserState, IRootState>) {
    const { $sentry: sentry } = useNuxtApp()
    const currentUser = await getCurrentUser.execute()

    currentUser.customUserId = context.state.current?.customUserId
    context.commit(USER_ACTIONS.SET_CURRENT_USER, currentUser)
    context.dispatch('setUserConnectionStatus', currentUser)
    context.dispatch('shop/syncFavoriteStoreId', currentUser.favoriteStoreId, { root: true })

    if (process.client) {
      sentry.setUser({ id: currentUser.customUserId })
    }
    return context.state
  },
  async setUserConnectionStatus(context: ActionContext<IUserState, IRootState>, user: User) {
    let connectionStatus = EUserStatus.NOT_CONNECTED
    if (user?.email) {
      connectionStatus = user.segments.includes(UserSegments.loyalty)
        ? EUserStatus.FID_CONNECTED
        : EUserStatus.CONNECTED
    }
    context.commit(USER_ACTIONS.SET_USER_CONNECTION, connectionStatus)
  },
  async setUserLoaded(context: ActionContext<IUserState, IRootState>, isLoaded: boolean) {
    context.commit(USER_ACTIONS.SET_USER_LOADED, isLoaded)
  }
}

export default userActions

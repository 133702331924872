import type { IUserRepository } from '@contexts/user/infrastructure/interfaces/i-user.repository'
import type { Loyalty } from '@contexts/loyalty/domain/loyalty.interface'

export class GetCurrentLoyalty {
  constructor(public readonly userRepository: IUserRepository) {}

  async execute(): Promise<Loyalty | null> {
    return this.userRepository.getCurrentLoyalty().catch(() => null)
  }
}

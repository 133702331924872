import type { IProductAvailability } from '@domain/product/product.interface'
import { LogisticRoute } from '@invivodf/module-logistic-offer-client-sdk'
import type { OfferUnifiedDTO } from '@infrastructure/offer'
import { DiscountScope, type ShippingDTO } from '@invivoretail/module-offers-interfaces'
import { ProductSheetOfferMapper } from '@contexts/product/infrastructure/product-sheet/mappers/product-sheet-offer.mapper'
import type { OfferPricing } from '@contexts/offers/infrastructure/offer-pricing.interface'
import type { ProductSheetOfferDiscount } from '@/contexts/product/domain/product-sheet/product-sheet-offer.model'

export class OfferMapper {
  static extractAvailabilitiesFromOfferUnified = (offers: OfferUnifiedDTO[] | null): IProductAvailability => {
    const defaultAvailabilities: IProductAvailability = {
      [LogisticRoute.HomeDelivery]: false,
      [LogisticRoute.ClickAndCollect]: false
    }
    if (!offers?.length) return defaultAvailabilities

    return offers
      .flatMap((offer) => offer.shippings)
      .reduce((availabilities: IProductAvailability, shipping: ShippingDTO) => {
        if (
          shipping.logisticRoute === LogisticRoute.ClickAndCollect ||
          shipping.logisticRoute === LogisticRoute.StoreDelivery
        )
          return {
            ...availabilities,
            [LogisticRoute.ClickAndCollect]: shipping.stock > 0
          }

        if (shipping.logisticRoute === LogisticRoute.HomeDelivery)
          return {
            ...availabilities,
            [LogisticRoute.HomeDelivery]: shipping.stock > 0
          }

        return availabilities
      }, defaultAvailabilities)
  }

  static extractOfferPricing = (offers: OfferUnifiedDTO[] | null): OfferPricing | null => {
    if (!offers?.length) return null
    const {
      price: { withVat: originalPrice }
    } = offers[0]
    let discount: ProductSheetOfferDiscount | undefined

    if (offers[0].discount) {
      const { discount: offerDiscount, discountedPrice } = offers[0]
      discount = {
        reference: offerDiscount.reference,
        amount: offerDiscount.description.displayedAmount,
        discountedPrice:
          offerDiscount?.scope === DiscountScope.Group ? originalPrice : discountedPrice?.withVat ?? originalPrice,
        originalPrice,
        publicName: offerDiscount.description.publicName,
        segment: offerDiscount.segment,
        unit: ProductSheetOfferMapper.toProductSheetOfferDiscountUnit(offerDiscount.description.unit),
        scope: ProductSheetOfferMapper.toProductSheetOfferDiscountScope(offerDiscount.scope),
        groupSize: offerDiscount.groupSize,
        validityEndDate: offerDiscount.description.endDate
      }
    }

    return {
      discount,
      price: discount?.discountedPrice ?? originalPrice
    }
  }
}

/* eslint-disable no-console */
import type { Level, Logger as PinoLogger } from 'pino'
import pino from 'pino'
import {
  type H3Event,
  type EventHandlerRequest,
  getRequestHeader,
  getRequestHost,
  getRequestURL,
  fromNodeMiddleware,
  type NodeMiddleware
} from 'h3'
import type { AxiosError } from 'axios'

interface LoggerConfig {
  level?: Level
  name: string
  domain: string
  sub_domain: string
  overrideConsole?: boolean
  trackUncaughtException?: boolean
  trackUnhandledRejection?: boolean
}

export const pathToObjectKeyValue = (path: string): Record<string, string> => {
  const decoded = decodeURIComponent(path)
  const obj: Record<string, string> = {}
  const [pathName, queryString] = decoded.split('?')
  const queryKeyValue = queryString?.split('&')
  obj.path = pathName
  queryKeyValue?.forEach((query) => {
    const [key, value] = query.split('=')
    obj[key] = value
  })
  return obj
}

const extractNuxtErrorFromH3Event = (event: H3Event<EventHandlerRequest>) => {
  if (event.path?.includes('/__nuxt_error')) {
    const keyValue = pathToObjectKeyValue(event.path)
    return {
      url: keyValue.url,
      statusCode: Number.isInteger(Number(keyValue.statusCode)) ? Number(keyValue.statusCode) : undefined,
      statusMessage: keyValue.statusMessage,
      message: keyValue.message
    }
  }
  return null
}

const errorParserFromH3Event = (event: H3Event<EventHandlerRequest>) => {
  const error = extractNuxtErrorFromH3Event(event)

  return {
    isError: error !== null,
    correlation_id: getRequestHeader(event, 'x-request-id'),
    host: getRequestHost(event),
    message: error?.message || '',
    response: {
      status_code: error?.statusCode || event.node.res.statusCode,
      status_message: error?.statusMessage || '',
      duration: ''
    },
    request: {
      method: event.node.req.method,
      uri: error?.url || getRequestURL(event),
      user_agent: getRequestHeader(event, 'user-agent'),
      path: error?.url || event.path
    }
  }
}

const errorParserFromAxiosError = (error: AxiosError) => {
  return {
    isError: true,
    correlation_id: error.config?.headers['x-request-id'],
    host: error.config?.baseURL,
    message: error.message,
    stderr: error.stack,
    response: {
      status_code: error.status,
      duration: ''
    },
    request: {
      method: error.config?.method,
      uri: `${error.config?.baseURL}${error.config?.url}`,
      user_agent: error.config?.headers['user-agent'],
      path: error.config?.url
    }
  }
}

export const createLogger = (config: LoggerConfig): PinoLogger => {
  const defaultConfig: LoggerConfig = {
    level: 'info',
    overrideConsole: true,
    trackUncaughtException: true,
    trackUnhandledRejection: true
  } as LoggerConfig

  const mergedConfig = { ...defaultConfig, ...config }

  const instance = pino({
    level: mergedConfig.level || 'info',
    formatters: {
      level: (label) => ({ level: label }),
      bindings: (bindings) => {
        return {
          pid: bindings.pid,
          host: bindings.hostname,
          node_version: process.version,
          logger_name: mergedConfig.name,
          domain: mergedConfig.domain,
          sub_domain: mergedConfig.sub_domain || ''
        }
      }
    },
    redact: {
      paths: ['req.headers', 'res.headers'],
      remove: true
    },
    timestamp: () => `,"timestamp":"${new Date().toISOString()}"`,
    messageKey: 'message'
  })

  if (mergedConfig.overrideConsole) {
    console.error = instance.error.bind(instance)
    console.warn = instance.warn.bind(instance)
    console.info = instance.info.bind(instance)
    console.debug = instance.debug.bind(instance)
    console.trace = instance.trace.bind(instance)
  }

  return instance
}

export const buildHttpLogger = async (logger: PinoLogger): Promise<NodeMiddleware> => {
  const { default: pinoHttp } = await import('pino-http')
  const { randomUUID } = await import('crypto')
  return pinoHttp({
    logger,
    genReqId(req, res) {
      const existingID = req.id ?? req.headers['x-request-id']
      if (existingID) return existingID
      const id = randomUUID()
      res.setHeader('X-Request-Id', id)
      return id
    },
    customLogLevel(req, res, error) {
      if (res.statusCode >= 400 && res.statusCode < 500) return 'warn'
      if (res.statusCode >= 500 || error) return 'error'
      if (req.url?.includes('/__nuxt_error')) return 'error'
      if (res.statusCode >= 300) return 'info'
      return 'silent'
    },
    customAttributeKeys: {
      responseTime: 'response_time'
    },
    customProps: (req, res) => {
      const error = (req.url?.includes('/__nuxt_error') && pathToObjectKeyValue(req.url)) || {}
      return {
        correlation_id: req.id,
        request: {
          method: req.method,
          uri: `${req.headers.host}${req.url}`,
          path: error?.url || req.url,
          user_agent: req.headers['user-agent']
        },
        response: {
          status_code: error?.statusCode || res.statusCode,
          status_message: error?.statusMessage || res.statusMessage
        }
      }
    }
  })
}

const logger = createLogger({
  level: process.env.NUXT_LOG_LEVEL as Level,
  name: 'logger-frontend',
  domain: 'enseignes',
  sub_domain: process.env.NUXT_PUBLIC_BRAND_NAME || ''
})

export const h3Middleware = async (event: H3Event<EventHandlerRequest>) => {
  const httpLogger = await buildHttpLogger(logger)
  const middleware = fromNodeMiddleware(httpLogger)
  return middleware(event)
}

export const logH3Event = (event: H3Event<EventHandlerRequest>) => {
  const error = errorParserFromH3Event(event)
  if (error.isError) {
    logger.error(error, 'H3 Event Error')
  }
}

export const logAxiosError = (error: AxiosError) => {
  const parsedError = errorParserFromAxiosError(error)
  logger.error(parsedError)
}

export default logger

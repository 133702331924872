import {
  EStoreType,
  type BasicStore,
  type IStoreLocatorSDK,
  type Store,
  type StoreDetails
} from '@invivoretail/module-store-locator-sdk'
import type { IStoreLocatorRepository } from '@contexts/store-locator/domain/store-locator-repository.interface'
import type { IStoreCoordinates } from '@contexts/store-locator/domain/coordinates.interface'
import type { StoreOffers } from '@contexts/store-locator/domain/store-offers.interface'
import type { OfferDataSource } from '@infrastructure/offer'
import { OfferMapper } from '@contexts/offers/infrastructure/mappers/offer.mapper'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'
import type { IUseCookie } from '@/application/composables/cookie'
import type { UserDataSource } from './data-sources/user.data-source'

export class StoreLocatorRepository implements IStoreLocatorRepository {
  constructor(
    private readonly storeLocatorSDK: IStoreLocatorSDK,
    private readonly offersDataSource: OfferDataSource,
    private readonly userDataSource: UserDataSource,
    private webstoreId: string | null,
    private needCookieStoreUpdate: boolean,
    private useMangopay: boolean
  ) {}

  async fetchStoreBySlug(slug: string): Promise<StoreDetails | null> {
    return this.storeLocatorSDK.getDetails(slug).catch((err: Error) => {
      throw err
    })
  }

  async fetchStores(coordinates: IStoreCoordinates, onlyEnrolled: boolean): Promise<Store[]> {
    try {
      if (onlyEnrolled) {
        return this.storeLocatorSDK.findEnrolledByGeolocation(coordinates.lat.toString(), coordinates.lng.toString())
      }
      return this.storeLocatorSDK.findByGeolocation(coordinates.lat.toString(), coordinates.lng.toString())
    } catch (e) {
      return Promise.resolve([])
    }
  }

  async fetchAllStores(): Promise<BasicStore[]> {
    return this.storeLocatorSDK
      .getAll()
      .then((basicStores: BasicStore[]) => basicStores.filter((store) => store.active === true))
      .catch(() => [])
  }

  async fetchStoresWithOffers(coordinates: IStoreCoordinates, productId: string): Promise<StoreOffers[]> {
    const stores = await this.fetchStores(coordinates, true).catch(() => [])
    if (!stores.length) return []

    const offersDTO = await Promise.all(
      stores.map(({ id: storeId }) => this.offersDataSource.getBestOfferByProductIdAndStoreId(productId, storeId))
    ).catch(() => [])
    const availabilities = offersDTO.map(OfferMapper.extractAvailabilitiesFromOfferUnified)
    return stores.map((store, index) => ({ ...store, availabilities: availabilities[index] || [] } as StoreOffers))
  }

  async saveCurrentStore(data: ICurrentStore, shouldSaveInUser: boolean, useStoreCookie: IUseCookie): Promise<void> {
    if (shouldSaveInUser) await this.userDataSource.updateUserFavoriteStoreId(data.id)
    await StoreLocatorRepository.setExpiringCookie(data, useStoreCookie)
  }

  async getCurrentStore(
    isUserConnected: boolean,
    updateCookie: IUseCookie,
    storeCookie: IUseCookie
  ): Promise<{ currentStore: ICurrentStore | null; expirationDate: Date | null }> {
    type ExpiringCurrentStore = { currentStore: ICurrentStore; expirationDate: string }
    const isExpiringCurrentStore = (
      cookieResult: ExpiringCurrentStore | ICurrentStore
    ): cookieResult is ExpiringCurrentStore => 'expirationDate' in cookieResult
    const isFavoriteStoreUpdated = updateCookie.getCookie()
    const result = storeCookie.getCookie<ExpiringCurrentStore | ICurrentStore>()

    if (!result) return { currentStore: null, expirationDate: null }
    const currentStore = isExpiringCurrentStore(result) ? result.currentStore : result
    const expirationDate = isExpiringCurrentStore(result) ? new Date(result.expirationDate) : null
    if (!isFavoriteStoreUpdated && currentStore.id === this.webstoreId && this.needCookieStoreUpdate) {
      storeCookie.removeCookie()
      updateCookie.setCookie('fullfilled')
      if (isUserConnected) await this.userDataSource.updateUserFavoriteStoreId(null)
      return { currentStore: null, expirationDate: null }
    }
    return { currentStore, expirationDate }
  }

  async refreshCurrentStore(
    currentStore: ICurrentStore,
    isUserConnected: boolean,
    useStoreCookie: IUseCookie
  ): Promise<ICurrentStore | null> {
    const freshCurrentStore = await this.storeLocatorSDK.getById(currentStore.id)
    if (!freshCurrentStore || !freshCurrentStore.types.includes(EStoreType.Actif)) {
      if (isUserConnected) await this.userDataSource.updateUserFavoriteStoreId(null)
      useStoreCookie.removeCookie()
      return null
    }
    StoreLocatorRepository.setExpiringCookie(freshCurrentStore, useStoreCookie)
    return freshCurrentStore
  }

  async getStoreById(storeId: string): Promise<ICurrentStore | null> {
    const storeDetails = await this.storeLocatorSDK.getById(storeId).catch((err) => {
      throw err
    })

    if (!storeDetails?.types?.includes(EStoreType.Actif)) return null

    return (
      storeDetails && {
        id: storeDetails.id,
        name: storeDetails.name,
        shortName: storeDetails.shortName,
        slug: storeDetails.slug,
        groupId: storeDetails.groupId,
        address: storeDetails.address,
        openingHours: storeDetails.openingHours
      }
    )
  }

  isEnrolledStore(store: Store): boolean {
    return this.storeLocatorSDK.isEnrolledStore(store, this.useMangopay)
  }

  private static setExpiringCookie(data: ICurrentStore, useStoreCookie: IUseCookie): void {
    const expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + 1)
    useStoreCookie.setCookie({ currentStore: data, expirationDate: expirationDate.toISOString() })
  }
}

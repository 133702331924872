import type { OffersClient } from '@invivoretail/module-offers-sdk'
import type { OfferUnifiedDTO } from './offers-api/offer-unified-dto'

export interface IOfferDataSource {
  getBestOfferByProductIdAndStoreId(
    productId: string,
    storeId: string,
    userId?: string
  ): Promise<OfferUnifiedDTO[] | null>
  getBestAndAdditionalOffersByProductId(
    productId: string,
    storeId: string,
    userId?: string
  ): Promise<{ bestOffers: OfferUnifiedDTO[]; additionalOffers: OfferUnifiedDTO[] } | null>
}

export class OfferDataSource implements IOfferDataSource {
  constructor(private readonly offersClient: OffersClient) {}

  /**
   * Get offers by productId and storeId
   * @param productId : string
   * @param storeId : string
   * @param {string=} userId
   * @returns : Promise<OfferDto[] | null>
   *
   */
  async getBestOfferByProductIdAndStoreId(
    productId: string,
    storeId: string,
    userId?: string
  ): Promise<OfferUnifiedDTO[] | null> {
    try {
      const { bestOffers } = await this.offersClient.getBestAndAdditionalOffersByProductId(
        storeId,
        productId,
        userId || null
      )
      return bestOffers
    } catch {
      return null
    }
  }

  /**
   * Get best and additional offers by productId and storeId
   * @param productId : string
   * @param storeId : string
   * @param {string=} userId
   * @returns : Promise<{ bestOffers: OfferDto[]; additionalOffers: OfferDto[] } | null>
   *
   */
  async getBestAndAdditionalOffersByProductId(
    productId: string,
    storeId: string,
    userId?: string
  ): Promise<{ bestOffers: OfferUnifiedDTO[]; additionalOffers: OfferUnifiedDTO[] } | null> {
    try {
      return this.offersClient.getBestAndAdditionalOffersByProductId(storeId, productId, userId || null)
    } catch {
      return null
    }
  }
}

import type { IRootState } from '@application/store/state.interface'
import type { ActionContext, ActionTree } from '@utils/esmParser'
import type { IAxeptioState } from '@application/store/axeptio/interface'
import { axeptioTypes } from '@application/store/axeptio/constants'

const axeptioActions: ActionTree<IAxeptioState, IRootState> = {
  async setReady(context: ActionContext<IAxeptioState, IRootState>, isReady: boolean) {
    context.commit(axeptioTypes.SET_READY, isReady)

    return context.state
  },
  async setConsents(context: ActionContext<IAxeptioState, IRootState>, consents: Record<string, boolean>) {
    context.commit('SET_CONSENTS', consents)
  }
}

export default axeptioActions

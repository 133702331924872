import type { GetterTree } from '@utils/esmParser'
import { useConfig } from '@application/composables/config'
import { CountryCode } from '@domain/current-store/current-store.interface'

import { useNuxtApp } from '#imports'
import type { IShopState } from './interface'
import type { IRootState } from '../state.interface'
import { EShopStatus } from './constants'

const shopGetters: GetterTree<IShopState, IRootState> = {
  currentStatus(state: IShopState): EShopStatus {
    const { $i18n: i18n } = useNuxtApp()
    const { getWebstore } = useConfig()
    const { id: webstoreId } = getWebstore(i18n.locale.value)

    if (state.current?.id === webstoreId) return EShopStatus.WEB_STORE
    if (state.current?.id) return EShopStatus.HAS_STORE
    return EShopStatus.STORELESS
  },
  hasFractionalPaymentEnabled: (state: IShopState): boolean => state.current?.address?.country !== CountryCode.ES
}

export default shopGetters

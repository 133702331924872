import type {
  DeliveryTypeOffers,
  ProductSheetOffer,
  ProductSheetOfferDiscount
} from '@contexts/product/domain/product-sheet/product-sheet-offer.model'
import {
  ProductSheetDeliveryMethod,
  ProductSheetOfferDiscountUnit
} from '@contexts/product/domain/product-sheet/product-sheet-offer.model'
import { ProductDiscountScope } from '@domain/product/product.interface'
import type { OfferUnifiedDTO, ShippingMethodUnifiedDTO } from '@/infrastructure/offer'
import type { LogisticDelay } from '@invivodf/module-logistic-offer-client-sdk'
import { LogisticDelayUnit } from '@invivodf/module-logistic-offer-client-sdk'
import type { DiscountDTO, PriceDTO } from '@invivoretail/module-offers-interfaces'
import { DeliveryType, DiscountScope, DiscountUnit } from '@invivoretail/module-offers-interfaces'

type LogisticDelayDTO = OfferUnifiedDTO['shippings'][number]['minDeliveryDelay']

export class ProductSheetOfferMapper {
  static toProductSheetOffers(offers: OfferUnifiedDTO[]): DeliveryTypeOffers {
    return {
      clickAndCollect: ProductSheetOfferMapper.findOfferByDeliveryType(offers, DeliveryType.ClickAndCollect),
      shipping: ProductSheetOfferMapper.findOfferByDeliveryType(offers, DeliveryType.Shipping)
    }
  }

  private static findOfferByDeliveryType(
    offers: OfferUnifiedDTO[],
    deliveryType: DeliveryType
  ): ProductSheetOffer | undefined {
    const offer = offers.find((offerDto) =>
      offerDto.shippings.some((shipping) => shipping.deliveryType === deliveryType)
    )

    if (!offer) {
      return undefined
    }

    const {
      productId,
      reference,
      seller,
      price: { withVat: priceIncludingVat }
    } = offer

    const shipping = offer.shippings.find((shippingDto) => shippingDto.deliveryType === deliveryType)!
    const deliveryMethod = ProductSheetOfferMapper.toDeliveryMethod(shipping.logisticRoute)
    const delay = ProductSheetOfferMapper.toLogisticDelay(shipping.minDeliveryDelay)

    let productSheetOfferDiscount
    const { discount, discountedPrice } = offer

    if (discount) {
      productSheetOfferDiscount = ProductSheetOfferMapper.toProductSheetOfferDiscount(
        priceIncludingVat,
        discountedPrice,
        discount
      )
    }

    return {
      productId,
      reference,
      sellerId: seller.id,
      seller: {
        id: seller.id,
        name: seller.name,
        isMarketPlace: seller.isMarketplace
      },
      supplierId: shipping.supplierId,
      deliveryMethod,
      displayedPrice: productSheetOfferDiscount ? productSheetOfferDiscount.discountedPrice : priceIncludingVat,
      stock: shipping.stock,
      deliveryExpectation: {
        canDeliverByClickAndCollect: deliveryType === DeliveryType.ClickAndCollect,
        canDeliverByShipping: deliveryType === DeliveryType.Shipping,
        estimatedMinimalShippingCost: shipping.minPrice,
        delay,
        earliestDeliveryDate: shipping.earliestDeliveryDate ? new Date(shipping.earliestDeliveryDate) : null
      },
      discount: productSheetOfferDiscount
    }
  }

  private static toLogisticDelay(delay: LogisticDelayDTO): LogisticDelay | null {
    if (!delay) return null

    let unit
    switch (delay.unit) {
      case 'hour':
        unit = LogisticDelayUnit.Hour
        break
      case 'day':
        unit = LogisticDelayUnit.Day
        break
      case 'working_day':
        unit = LogisticDelayUnit.WorkingDay
        break
      case 'week':
        unit = LogisticDelayUnit.Week
        break
      case 'month':
        unit = LogisticDelayUnit.Month
        break
      default:
        return null
    }

    return { value: delay.value, unit }
  }

  private static toDeliveryMethod = (route: ShippingMethodUnifiedDTO): ProductSheetDeliveryMethod => {
    switch (route) {
      case 'click_and_collect':
        return ProductSheetDeliveryMethod.CLICK_AND_COLLECT
      case 'home_delivery':
        return ProductSheetDeliveryMethod.LAD
      case 'store_delivery':
        return ProductSheetDeliveryMethod.STORE_DELIVERY
      default:
        throw new Error('Unknown delivery method')
    }
  }

  public static toProductSheetOfferDiscountUnit = (unit: DiscountUnit): ProductSheetOfferDiscountUnit => {
    switch (unit) {
      case DiscountUnit.Percent:
        return ProductSheetOfferDiscountUnit.PERCENTAGE
      case DiscountUnit.Unit:
        return ProductSheetOfferDiscountUnit.EURO
      default:
        throw new Error('Unknown discount unit')
    }
  }

  public static toProductSheetOfferDiscountScope = (scope: DiscountScope): ProductDiscountScope => {
    switch (scope) {
      case DiscountScope.Article:
        return ProductDiscountScope.ARTICLE
      case DiscountScope.Group:
        return ProductDiscountScope.GROUP
      default:
        throw new Error('Unknown discount scope')
    }
  }

  private static toProductSheetOfferDiscount(
    originalPrice: number,
    discountedPrice: PriceDTO | undefined,
    discount: DiscountDTO
  ): ProductSheetOfferDiscount {
    const productSheetOfferDiscountUnit: ProductSheetOfferDiscountUnit =
      ProductSheetOfferMapper.toProductSheetOfferDiscountUnit(discount.description.unit)

    return {
      reference: discount.reference,
      amount: discount.description.displayedAmount,
      discountedPrice:
        discount?.scope === DiscountScope.Group ? originalPrice : discountedPrice?.withVat ?? originalPrice,
      originalPrice,
      publicName: discount.description.publicName,
      segment: discount.segment,
      unit: productSheetOfferDiscountUnit,
      scope: ProductSheetOfferMapper.toProductSheetOfferDiscountScope(discount.scope),
      groupSize: discount.groupSize,
      validityEndDate: discount.description.endDate
    }
  }
}

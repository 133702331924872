import type { FileSystemCache } from 'file-system-cache'
import logger from '@/utils/logger/logger'
import Config, { getConfigFor } from './config'
import type { Cache } from './utils/cache.interface'
import { checkDataOnline } from './utils/online'

const API_CACHE = '/api/v1/check-cache/'
const DISABLE_FS_CACHE = process.env.NUXT_DISABLE_REQUEST_CACHE === 'true'

export class FileCache implements Cache {
  private cache?: FileSystemCache | null

  constructor() {
    if (DISABLE_FS_CACHE) {
      this.cache = undefined
      return
    }
    /* eslint-disable-next-line no-console */
    logger.info('FileCache constructor')
    if (process.server) {
      import('file-system-cache')
        .then(({ FileSystemCache }) => {
          this.cache = new FileSystemCache({ basePath: './.cache', ttl: Config.DEFAULT_CACHE_TTL })
        })
        .catch((e) => {
          /* eslint-disable-next-line no-console */
          logger.error(e, 'file-system-cache')
        })
    }
  }

  async get<T>(name?: string, options?: { checkOnline: boolean }): Promise<T | null> {
    let data

    if (!name) return Promise.resolve(null)

    try {
      data = await this.cache?.get(name)
    } catch (e) {
      return null
    }

    if (!data && options?.checkOnline) {
      return checkDataOnline(name, API_CACHE)
    }
    return data
  }

  set<T = unknown>(name: string, value: T): Promise<null> {
    const config = getConfigFor(name)
    if (config.activate) {
      return this.cache?.set(name, value, config.ttl).then(() => null) ?? Promise.resolve(null)
    }
    return Promise.resolve(null)
  }
}

export const fileCacheInstance = new FileCache()

import { defineNuxtPlugin } from '#imports'
import type { NuxtApp } from 'nuxt/app'
import { useConfig } from '@application/composables/config'
import {
  init as SentryInit,
  browserTracingIntegration,
  createTracingMixins,
  attachErrorHandler,
  setContext,
  setUser,
  setTag,
  addBreadcrumb,
  captureException,
  captureMessage
} from '@sentry/vue'

export default defineNuxtPlugin(async (nuxtApp: NuxtApp) => {
  const { vueApp } = nuxtApp
  const { getEnv } = useConfig()
  const isProduction = getEnv('ENVIRONMENT_NAME') === 'PRD'

  if (isProduction) {
    SentryInit({
      dsn: getEnv('SENTRY_DNS') || '',
      tracesSampleRate: 0.001,
      sampleRate: 0.01,
      environment: getEnv('ENVIRONMENT_NAME') || '',
      release: getEnv('VERSION') || '',
      app: [vueApp],
      integrations: [
        browserTracingIntegration({
          router: nuxtApp.$router
        })
      ],
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          // eslint-disable-next-line no-console
          console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint })
        }
        // Continue sending to Sentry
        return event
      }
    })

    vueApp.mixin(createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }))
    attachErrorHandler(vueApp, {
      logErrors: true,
      attachProps: true,
      attachErrorHandler: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update']
    })
  }

  return {
    provide: {
      sentry: {
        setContext,
        setUser,
        setTag,
        addBreadcrumb,
        captureException,
        captureMessage
      }
    }
  }
})

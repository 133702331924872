import { defineNuxtPlugin, useHead, useNuxtApp } from '#imports'
import { useConfig } from '@application/composables/config'
import type { NuxtApp } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp: NuxtApp) => {
  if (window.location.href.indexOf('/iframe') > -1) {
    return
  }
  const { getEnv } = useConfig()
  const { $i18n: i18n } = useNuxtApp()
  const axeptioClientId = getEnv('AXEPTIO_CLIENT_ID') || null
  const axeptioBase = getEnv('AXEPTIO_BASE') || null
  const axeptioDuration = getEnv('AXEPTIO_DURATION') || null
  const axeptioDomain = getEnv('AXEPTIO_DOMAIN') || ''

  const isAxeptioLoaded = (): boolean => {
    return Boolean(typeof window !== 'undefined' && window.axeptioSDK?.isReady)
  }

  const setupEventListeners = () => {
    const interval = setInterval(() => {
      if (isAxeptioLoaded()) {
        clearInterval(interval)

        window.axeptioSDK!.on('cookies:complete', (choices: Record<string, boolean>) => {
          nuxtApp.$store?.dispatch('axeptio/setConsents', choices)
        })
      }
    }, 200)
  }

  const initAxeptio = () => {
    if (axeptioClientId && axeptioBase && axeptioDuration) {
      const cookiesVersionLang = i18n.locale.value === 'es' ? `${axeptioBase}-es` : axeptioBase
      window.axeptioSettings = {
        clientId: axeptioClientId,
        cookiesVersion: cookiesVersionLang ?? undefined,
        userCookiesDuration: Number(axeptioDuration),
        userCookiesDomain: axeptioDomain
      }
    }

    useHead(
      {
        script: [
          {
            vmid: 'axeptio',
            src: 'https://static.axept.io/sdk-slim.js',
            defer: true,
            onload: () => {
              setupEventListeners()
            }
          }
        ]
      },
      { mode: 'client' }
    )
    nuxtApp.$store?.dispatch('axeptio/setReady', true)
  }

  initAxeptio()
})

import { useConfig } from '@application/composables/config'
import type { IShopState } from '@application/store/shop/interface'
import type { IRootState } from '@application/store/state.interface'
import { getCurrentStore, getStoreById, saveCurrentStore } from '@contexts/store-locator'
import type { ActionContext, ActionTree } from '@utils/esmParser'
import { shopTypes } from '@application/store/shop/constants'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'
import { cookieTypes, useCookie } from '@/application/composables/cookie'
import { getStoreBySlug, isEnrolledStore } from '@contexts/store-locator/store-locator-injection'
import { useNuxtApp } from '#imports'

const shopActions: ActionTree<IShopState, IRootState> = {
  async initShop(
    context: ActionContext<IShopState, IRootState>,
    { storeId, fromGoogleShopping }: { storeId?: string; fromGoogleShopping?: boolean } = {}
  ) {
    let currentStore: ICurrentStore | null
    const { $i18n: i18n } = useNuxtApp()
    const { getWebstore } = useConfig()

    const webStore = getWebstore(i18n.locale.value)

    const updateCookie = useCookie(cookieTypes.UPDATED_STORE)
    const storeCookie = useCookie(cookieTypes.STORE)
    currentStore = await getCurrentStore.execute(context.rootGetters['user/isConnected'], updateCookie, storeCookie)

    if (storeId) {
      const forceStore = await getStoreById.execute(storeId)
      if (forceStore) {
        const forceFullStore = await getStoreBySlug.execute(forceStore.slug)
        if (forceFullStore && isEnrolledStore.execute(forceFullStore)) currentStore = forceStore
        else currentStore = await getStoreById.execute(webStore.id)
      } else {
        currentStore = await getStoreById.execute(webStore.id)
      }
    } else if (fromGoogleShopping) {
      currentStore = await getStoreById.execute(webStore.id)
    }

    context.commit(shopTypes.SET_SHOP, currentStore)
    return context.state
  },

  async setShop(context: ActionContext<IShopState, IRootState>, newCurrentShop: ICurrentStore) {
    const cookie = useCookie(cookieTypes.STORE)
    if (newCurrentShop.groupId) {
      context.commit(shopTypes.SET_SHOP, newCurrentShop)
      await saveCurrentStore.execute(newCurrentShop, context.rootGetters['user/isConnected'], cookie)
    } else {
      const freshStore = await getStoreById.execute(newCurrentShop.id)
      context.commit(shopTypes.SET_SHOP, freshStore)
      await saveCurrentStore.execute(freshStore!, context.rootGetters['user/isConnected'], cookie)
    }

    return context.state
  },

  async syncFavoriteStoreId(context: ActionContext<IShopState, IRootState>, userFavoriteStoreId: string) {
    const { getWebstore } = useConfig()
    const { $i18n: i18n } = useNuxtApp()
    const webStore = getWebstore(i18n.locale.value)

    if (context.state.current?.id === webStore.id && !userFavoriteStoreId) {
      context.dispatch('setShop', null)
      return context.state
    }

    if (context.state.current?.id === userFavoriteStoreId) return context.state

    const currentStore = context.state.current?.id
      ? context.state.current
      : await getStoreById.execute(userFavoriteStoreId)

    if (currentStore) context.dispatch('setShop', currentStore)

    return context.state
  }
}

export default shopActions

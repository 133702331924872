import { useRuntimeConfig } from '#imports'
import type { IStoreLocatorSdkOptions } from '@invivoretail/module-store-locator-sdk'
import { RetailerCode } from '@invivoretail/module-catalog-interfaces'

/* eslint-disable no-underscore-dangle */
export type IUseConfigApi = (path?: string) => string

export enum EnvironmentName {
  PRD = 'PRD',
  QUA = 'QUA',
  TEST = 'TEST',
  OPS = 'OPS'
}

export const useConfig = () => {
  const getEnv = (variableName: string): string | null => {
    let config: unknown

    try {
      config = useRuntimeConfig()
    } catch (e) {
      config = process.client && window?.__NUXT__?.config
    }

    const configValue = () => {
      if (process.server) {
        return process.env[`NUXT_${variableName}`] || process.env[`NUXT_PUBLIC_${variableName}`]
      }
      return (
        (config as Record<string, unknown>)?.[variableName] ||
        (config as { public: Record<string, unknown> })?.public?.[variableName]
      )
    }

    return configValue()?.toString() ?? null
  }

  const getLocales = (): string[] => {
    const value = getEnv('LOCALES')
    return value?.split(',') ?? []
  }

  const getDalenysApiKey = (): { id: string; key: string } => ({
    id: getEnv('DALENYS_API_KEY_ID') || '',
    key: getEnv('DALENYS_API_KEY_VALUE') || ''
  })

  const getDalenysHostedFieldScript = (): string | null => {
    return getEnv('DALENYS_HOSTED_FIELD_SCRIPT')
  }

  const getBaseUrl = (): string | null => {
    return getEnv('BASE_URL')
  }

  const cartApiUrl = '/v1/bff/retail/carts'

  const getCartApiUrl = (path?: string): string => {
    return `${getEnv('CART_API_HOST')}${cartApiUrl}${path || ''}`
  }

  const cartV2ApiUrl = '/v2/bff/retail/carts'

  const getCartV2ApiUrl = (path?: string): string => {
    return `${getEnv('CART_API_HOST')}${cartV2ApiUrl}${path || ''}`
  }

  const checkoutV3ApiUrl = '/v1/bff/retail/checkouts'
  const getCheckoutApiUrl = (path?: string): string => {
    return `${getEnv('CHECKOUT_API')}${checkoutV3ApiUrl}${path || ''}`
  }

  const getClientOrderApiUrl = (path?: string): string => {
    return `${getEnv('CHECKOUT_API')}/v2/client-order${path || ''}`
  }

  const getDiscountApiUrl = (path?: string): string => {
    return `${getEnv('DISCOUNT_API_HOST')}${path || ''}`
  }

  const getUsersApiUrl = (path?: string): string => {
    return `${getEnv('USERS_API')}/v1/users${path || ''}`
  }

  const getCatalogApiUrl = (path?: string): string => {
    return `${getEnv('CATALOG_API_HOST')}/catalog${path || ''}`
  }

  const getPaymentApiUrl = (path?: string): string => {
    return `${getEnv('PAYMENT_API_HOST')}${path || ''}`
  }

  const getOffersApiUrl = (path?: string): string => {
    return `${getEnv('OFFERS_API_HOST')}${path || ''}`
  }

  const getTransacApiUrl = (path?: string): string => {
    return `${getEnv('TRANSAC_API')}${path || ''}`
  }

  const getStoreLocatorApiUrl = (path?: string): string => {
    return `${getEnv('STORE_LOCATOR_API')}${path || ''}`
  }

  const getBffApiUrl = (path?: string): string => `${getEnv('BFF_API_HOST') as string}/v1/bff/retail${path || ''}`

  const getWebstore = (locale: string): { id: string; slug: string; name: string; shortName: string } => {
    return {
      id: getEnv(`WEB_STORE_ID_${locale.toUpperCase()}`) as string,
      slug: getEnv(`WEB_STORE_SLUG_${locale.toUpperCase()}`) as string,
      name: getEnv(`WEB_STORE_NAME_${locale.toUpperCase()}`) as string,
      shortName: getEnv(`WEB_STORE_NAME_${locale.toUpperCase()}`) as string
    }
  }

  const getIzanamiUrl = () => (getEnv('IZANAMI_URL') as string) || null

  const getProductListProductsPerPage = (): number => Number(getEnv('PRODUCTS_TO_SHOW_PER_PAGE'))

  const isProductsListWithStockOnlyEnabled = (): boolean => Boolean(getEnv('PRODUCTS_LIST_WITH_STOCK_ONLY') === 'true')

  const isClickAndCollectFilterEnabled = (): boolean => Boolean(getEnv('CLICK_AND_COLLECT_FILTER_ENABLED') === 'true')

  const isLocaleSwitcherEnabled = (): boolean => Boolean(getEnv('LOCALE_SWITCHER') === 'true')

  const isMarketplaceActive = (): boolean => Boolean(getEnv('IS_MARKETPLACE_ACTIVE') === 'true')

  const isMenuSpecialOperation = (): boolean => Boolean(getEnv('MENU_SPECIAL_OPERATION') === 'true')

  const getStoreLocatorConfig = (): IStoreLocatorSdkOptions => ({
    config: {
      apiHost: getStoreLocatorApiUrl(),
      options: {
        callerId: getBaseUrl()!
      }
    },
    context: {
      brandName: getEnv('BRAND_NAME')!,
      kind: ['physical']
    }
  })

  const getRetailerCode = () => {
    const enseigne = (getEnv('BRAND_NAME')! as string).toUpperCase() as keyof typeof RetailerCode
    return RetailerCode[enseigne]
  }

  const isHreflangEnabled = (): boolean => Boolean(getEnv('USE_HREFLANG') === 'true')

  const getEnvironmentName = () => <EnvironmentName>getEnv('ENVIRONMENT_NAME')
  const isProductionEnv = () => getEnvironmentName() === EnvironmentName.PRD
  const isQualificationEnv = () => getEnvironmentName() === EnvironmentName.QUA
  const isTestEnv = () => getEnvironmentName() === EnvironmentName.TEST
  const isOpsEnv = () => getEnvironmentName() === EnvironmentName.OPS

  return {
    getEnv,
    getDalenysApiKey,
    getDalenysHostedFieldScript,
    getBaseUrl,
    getLocales,
    getCartApiUrl,
    getCartV2ApiUrl,
    getCheckoutApiUrl,
    getClientOrderApiUrl,
    getDiscountApiUrl,
    getUsersApiUrl,
    getCatalogApiUrl,
    getBffApiUrl,
    getProductListProductsPerPage,
    isProductsListWithStockOnlyEnabled,
    isClickAndCollectFilterEnabled,
    getTransacApiUrl,
    getStoreLocatorApiUrl,
    getStoreLocatorConfig,
    isLocaleSwitcherEnabled,
    getOffersApiUrl,
    getWebstore,
    getIzanamiUrl,
    getPaymentApiUrl,
    getRetailerCode,
    isMarketplaceActive,
    isMenuSpecialOperation,
    isHreflangEnabled,
    getEnvironmentName,
    isProductionEnv,
    isQualificationEnv,
    isTestEnv,
    isOpsEnv
  }
}

export type IUseConfig = ReturnType<typeof useConfig>

import type { CreateClientParams, EntryCollection, EntrySkeletonType } from 'contentful'
import type { DocumentNode } from 'graphql/index'
import type { ApolloQueryResult, ApolloLink } from '@apollo/client/core'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core'
import type { IContentfulClient } from '@infrastructure/contentful/contentful-client.interface'
import { onContentfulRejected } from '@infrastructure/contentful/data-sources/contentful.error'
import type { IContentfulParam } from '@infrastructure/contentful/data-sources/contentful-param.interface'
import { Contentful } from '@/utils/esmParser'
import { useConfig } from '@application/composables/config'
import type { ILocalized } from '@application/composables/locale'
import logger from '@/utils/logger/logger'

export class ContentfulClient implements IContentfulClient {
  config: ILocalized<CreateClientParams>

  configPreview?: ILocalized<CreateClientParams>

  clientApollo: ILocalized<ApolloClient<unknown>>

  constructor(locales: string[], currentEnv: IContentfulParam, previewEnv?: IContentfulParam) {
    this.config = {} as ILocalized<CreateClientParams>
    this.configPreview = {} as ILocalized<CreateClientParams>
    this.clientApollo = {} as ILocalized<ApolloClient<unknown>>
    const httpLink = {} as ILocalized<ApolloLink>
    const { getEnv } = useConfig()
    const resultCacheMaxSize = parseInt(getEnv('APOLLO_CACHE_SIZE') || '65536', 10) // Default : Math.pow(2, 16) => see doc
    const cache = new InMemoryCache({
      resultCacheMaxSize
    })
    locales?.forEach((locale) => {
      if (currentEnv.contentfulApiKey[locale]) {
        this.config[locale] = {
          space: currentEnv.contentfulSpaceId,
          accessToken: currentEnv.contentfulApiKey[locale]!,
          environment: ContentfulClient.contentfulEnvironmentName(currentEnv.environmentName[locale])
        }
        httpLink[locale] = createHttpLink({
          uri: `https://graphql.contentful.com/content/v1/spaces/${this.config[locale]?.space}/environments/${this.config[locale]?.environment}/?access_token=${this.config[locale]?.accessToken}`
        })
        this.clientApollo[locale] = new ApolloClient({
          link: httpLink[locale],
          cache
        })
      }
      if (previewEnv?.environmentName[locale] && previewEnv.contentfulSpaceId && previewEnv.contentfulApiKey[locale]) {
        this.configPreview![locale] = {
          space: previewEnv.contentfulSpaceId,
          accessToken: previewEnv.contentfulApiKey[locale]!,
          environment: ContentfulClient.contentfulEnvironmentName(previewEnv.environmentName[locale]),
          host: 'preview.contentful.com'
        }
      }
    })
  }

  private static contentfulEnvironmentName = (environmentName = 'DEV'): string => {
    return environmentName
  }

  fetchCollection<T extends EntrySkeletonType>(
    query: Record<string, number | string>,
    locale: string,
    isPreview?: boolean
  ): Promise<EntryCollection<T> | null> {
    if (!['es', 'fr'].includes(locale)) {
      throw new Error(`Invalid locale from fetchCollection ${locale}`)
    }
    if (isPreview && !this.configPreview) {
      return Promise.resolve(null)
    }
    const config = isPreview ? this.configPreview![locale] : this.config[locale]
    if (!config) {
      logger.error('No config found for locale', locale)
      return Promise.resolve(null)
    }
    const client = Contentful.createClient(config)
    return client.withoutUnresolvableLinks
      .getEntries<T>(query)
      .catch((e: { message: string }) => onContentfulRejected(e))
  }

  fetchGql<T>(query: DocumentNode, locale: string): Promise<ApolloQueryResult<T> | null> {
    if (!['es', 'fr'].includes(locale)) {
      throw new Error(`Invalid locale from fetchGql ${locale}`)
    }
    return this.clientApollo[locale]!.query<T>({ query })
      .then((result) => result as ApolloQueryResult<T>)
      .catch((e) => onContentfulRejected(e))
  }
}

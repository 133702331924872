import { toRefs, reactive, useLocalePath } from '#imports'
import {
  redirectionToLoginPage,
  logout as performLogout,
  handleRedirect as performRedirect,
  isUserAuthenticated as isAuthenticated,
  getAccessToken as getToken
} from '../../auth-injection'
import type { ILoginParamsDTO } from '../../domain/login-dto.model'

// This state is usefull for server side, cause the auth0 lib needs window object.
const state = reactive({ isAuthenticatedWithValidToken: false })
/* eslint-disable */
export const useAuthentication = () => {
  const localePath = useLocalePath()
  const setIsAuthenticatedWithValidToken = (isAuthenticatedWithValidToken: boolean) => {
    state.isAuthenticatedWithValidToken = isAuthenticatedWithValidToken
  }
  const login = (params: ILoginParamsDTO) => redirectionToLoginPage.execute(params)
  const logout = () => performLogout.execute(localePath('/'))
  const handleRedirect = () => performRedirect.execute()
  const isUserAuthenticated = () => isAuthenticated.execute()
  const getAccessToken = () => getToken.execute()

  return {
    ...toRefs(state),
    setIsAuthenticatedWithValidToken,
    login,
    logout,
    handleRedirect,
    isUserAuthenticated,
    getAccessToken
  }
}
/* eslint-enable */

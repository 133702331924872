import algoliasearch from 'algoliasearch/lite.js'
import algoliarecommend from '@algolia/recommend'
import { useConfig } from '@application/composables/config'
import { AlgoliaRecommendationDataSource } from '@infrastructure/algolia/algolia-recommendation/data-sources/algolia-recommendation.data-source'
import { RecommendationRepository } from '@infrastructure/algolia/algolia-recommendation/algolia-recommendation.repository'

import { izanamiDatasource } from '@infrastructure/izanami'
import type { IRecommendationRepository } from '@domain/algolia-recommendation/recommendation-repository.interface'
import type { IAlgoliaRecommendationDataSource } from '@domain/algolia-recommendation/recommendation-datasource.interface'
import { cacheInstance } from '@infrastructure/cache'
import { offerDataSource } from '@infrastructure/offer'
import type { SearchClient } from 'algoliasearch'
import { AlgoliaIndexMapper } from './algolia-index.mapper'

const { getEnv } = useConfig()
const clientId = getEnv('ALGOLIA_CLIENT_ID') as string
const clientSecret = getEnv('ALGOLIA_CLIENT_SECRET') as string
const recommendFeatureFlipId = getEnv('IZANAMI_FEATURE_ALGOLIA_RECOMMEND') || null
const environment = getEnv('ENVIRONMENT_NAME') as string

export const algoliaIndexMapper = new AlgoliaIndexMapper(
  getEnv('ALGOLIA_PRODUCTS_INDEX_NAME') as string,
  getEnv('ALGOLIA_CATEGORIES_INDEX_NAME') as string,
  getEnv('ALGOLIA_ARTICLES_INDEX_NAME') as string
)

export const searchClient: SearchClient = algoliasearch(clientId, clientSecret) as SearchClient
export const recommendClient = algoliarecommend(clientId, clientSecret)
export const recommendationDataSource: IAlgoliaRecommendationDataSource = new AlgoliaRecommendationDataSource(
  recommendClient,
  algoliaIndexMapper,
  izanamiDatasource,
  cacheInstance,
  recommendFeatureFlipId,
  environment
)
export const recommendationRepository: IRecommendationRepository = new RecommendationRepository(
  recommendationDataSource,
  offerDataSource
)

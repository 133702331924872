import { sdkStoreLocator } from '@/utils/esmParser'
import { GetAddressAutocomplete } from '@contexts/store-locator/use-cases/get-address-autocomplete/get-address-autocomplete.usecase'
import { StoreLocatorRepository } from '@contexts/store-locator/infrastructure/store-locator.repository'
import { authRequestService } from '@application/composables/auth-request'
import { GetStores } from '@contexts/store-locator/use-cases/get-stores/get-stores.usecase'
import { GetAllStores } from '@contexts/store-locator/use-cases/get-all-stores/get-all-stores.usecase'
import { GetCurrentStore } from '@contexts/store-locator/use-cases/get-current-store/get-current-store.usecase'
import { SaveCurrentStore } from '@contexts/store-locator/use-cases/save-current-store/save-current-store.usecase'
import { useConfig } from '@application/composables/config'
import { OfferDataSource } from '@/infrastructure/offer/offer.datasource'
import { GetStoresWithOffersUsecase } from '@contexts/store-locator/use-cases/get-stores-with-offers/get-stores-with-offers.usecase'
import { addressAutocompleteRepository } from '@infrastructure/address-autocomplete'
import { IsEnrolledStore } from '@contexts/store-locator/use-cases/is-enrolled-store/is-enrolled-store.usecase'
import { OffersClient } from '@invivoretail/module-offers-sdk'
import { GetStoreBySlug } from './use-cases/get-store-by-slug/get-store-by-slug.usecase'
import { GetStoreById } from './use-cases/get-store-by-id/get-store-by-id.usecase'
import { UserDataSource } from './infrastructure/data-sources/user.data-source'

const { getStoreLocatorConfig, getUsersApiUrl, getOffersApiUrl, getEnv } = useConfig()

const offersClient = new OffersClient({ baseUrl: getOffersApiUrl(), callerId: `enseignes-${getEnv('BRAND_NAME')}` })
const offersDataSource = new OfferDataSource(offersClient)
const userDataSource = new UserDataSource(authRequestService, getUsersApiUrl)
const storeLocatorSDK = sdkStoreLocator(getStoreLocatorConfig())
const webstoreId = getEnv('WEB_STORE_ID')
const needCookieStoreUpdate = getEnv('NEED_COOKIE_STORE_UPDATE')
const useMangopay = getEnv('USE_MANGOPAY')

const storeLocatorRepository = new StoreLocatorRepository(
  storeLocatorSDK,
  offersDataSource,
  userDataSource,
  webstoreId,
  needCookieStoreUpdate === 'true',
  useMangopay === 'true'
)

const getAddressAutocomplete = new GetAddressAutocomplete(addressAutocompleteRepository)
const getStores = new GetStores(storeLocatorRepository)
const getStoresWithOffers = new GetStoresWithOffersUsecase(storeLocatorRepository)
const getAllStores = new GetAllStores(storeLocatorRepository)

const getCurrentStore = new GetCurrentStore(storeLocatorRepository)
const saveCurrentStore = new SaveCurrentStore(storeLocatorRepository)

const getStoreBySlug = new GetStoreBySlug(storeLocatorRepository)

const getStoreById = new GetStoreById(storeLocatorRepository)
const isEnrolledStore = new IsEnrolledStore(storeLocatorRepository)

export {
  getAddressAutocomplete,
  getStores,
  getAllStores,
  getCurrentStore,
  saveCurrentStore,
  getStoreBySlug,
  getStoreById,
  getStoresWithOffers,
  isEnrolledStore
}

import type { IRequest } from '@application/composables/request'
import { Address } from '@/domain/address'
import type { IUserDatasource } from '@contexts/user/infrastructure/interfaces/i-user.datasource'
import type { User } from '@contexts/user/domain/user.model'
import type { IUserOptinsRequest } from '@contexts/user/infrastructure/mappers/optins.mapper'
import type { Optins } from '@contexts/user/domain/optins.model'
import type { LinkLoyalty } from '@contexts/user/domain/link-loyalty.model'
import type { Loyalty } from '@contexts/loyalty/domain/loyalty.interface'

export class UserDataSource implements IUserDatasource {
  constructor(public requestService: IRequest, public getUsersApiUrl: (path?: string) => string) {}

  async getCurrentUser(): Promise<User> {
    const urlUser = this.getUsersApiUrl(`/me?getErrors=true&bypassLoyalty=true`)

    return this.requestService.get<User>(urlUser)
  }

  async postAddress(address: Address): Promise<Address> {
    const urlAddresses = this.getUsersApiUrl(`/me/addresses`)

    return this.requestService.post<Address>(urlAddresses, {
      ...address,
      firstName: address.firstName.trim(),
      lastName: address.lastName.trim()
    })
  }

  async deleteAddress(addressId: string): Promise<Address> {
    const urlAddresses = this.getUsersApiUrl(`/me/addresses/${addressId}`)

    return this.requestService.delete<Address>(urlAddresses)
  }

  async updateAddress(newAddress: Address): Promise<Address> {
    const urlAddresses = this.getUsersApiUrl(`/me/addresses/${newAddress.id}`)

    const newAddressWithoutId = newAddress
    delete newAddressWithoutId.id

    return this.requestService.put<Address>(urlAddresses, {
      ...newAddressWithoutId,
      firstName: newAddress.firstName.trim(),
      lastName: newAddress.lastName.trim()
    })
  }

  async updateUserInformation(user: User): Promise<User> {
    const urlUser = this.getUsersApiUrl(`/me`)

    return this.requestService.put<User>(urlUser, {
      firstName: user.firstName.trim(),
      lastName: user.lastName.trim()
    })
  }

  async resetPassword(): Promise<void> {
    const urlUser = this.getUsersApiUrl(`/me/reset-password`)
    await this.requestService.get(urlUser)
  }

  async toggleIsDefaultAddress(addressId: string): Promise<Address> {
    const urlAddresses = this.getUsersApiUrl(`/me/addresses/${addressId}/toggle-default`)

    return this.requestService.put<Address>(urlAddresses, {
      addressId
    })
  }

  async linkToLoyalty(cardNumber: number, postalCode?: string): Promise<LinkLoyalty> {
    const urlLoyalty = this.getUsersApiUrl(`/me/loyalty/link-to-loyalty-client`)

    return this.requestService.post<LinkLoyalty>(urlLoyalty, {
      cardNumber,
      postalCode
    })
  }

  async getDefaultAddress(): Promise<Address> {
    const urlAddresses = this.getUsersApiUrl(`/me/addresses`)

    return this.requestService.get<Address[]>(urlAddresses).then((addresses) => {
      if (addresses && addresses.length) {
        const defaultAddress: Address | undefined = addresses.find((value: Address) => value?.defaultAddress)

        return defaultAddress || addresses[0]
      }
      return Address.getEmptyAddress()
    })
  }

  async getBillingAddress(): Promise<Address> {
    const urlAddresses = this.getUsersApiUrl(`/me/addresses`)

    return this.requestService.get<Address[]>(urlAddresses).then((addresses) => {
      if (addresses && addresses.length) {
        const billingAddress: Address | undefined = addresses.find((value: Address) => value?.billingAddress)

        if (billingAddress) {
          return billingAddress
        }
        const defaultAddress: Address | undefined = addresses.find((value: Address) => value?.defaultAddress)

        return defaultAddress || addresses[0]
      }
      return Address.getEmptyAddress()
    })
  }

  async updateOptins(newOptins: IUserOptinsRequest): Promise<Optins> {
    const urlOptins = this.getUsersApiUrl(`/me/optins`)
    return this.requestService.put<Optins>(urlOptins, newOptins)
  }

  async getCurrentUserLoyalty(): Promise<Loyalty> {
    const url = this.getUsersApiUrl(`/me/loyalty`)
    return this.requestService.get(url)
  }
}

import { BREAKPOINTS } from '@invivodf/ui-kit'
import type { ImageSource } from '@application/types/image-dataset'
import type { CommonLinkItem } from '@/domain/contentful/common-link-item.model'
import type { CommonImageItem } from '@/domain/contentful/common-image-item.model'

export class CommonAdaptativeImageItem {
  sources: ImageSource[]

  isAdaptativeImage: boolean

  isAdaptativeImageBanner: boolean

  isAdaptativeImageArticle: boolean

  constructor(
    public title: string,
    public link: CommonLinkItem | null,
    public imageDesktop?: CommonImageItem | null,
    public imageMobile?: CommonImageItem | null,
    public description?: string,
    public promotionId?: string,
    public buttonText?: string
  ) {
    this.isAdaptativeImage = true
    this.isAdaptativeImageBanner = Boolean(this.promotionId)
    this.isAdaptativeImageArticle = Boolean(!this.promotionId)
    this.sources = [
      {
        url: this.imageDesktop?.src || this.imageMobile?.src || '',
        media: `(min-width: ${BREAKPOINTS.small}px)`,
        sizes: '100vw'
      },
      { url: this.imageMobile?.src || this.imageDesktop?.src || '', sizes: '100vw' }
    ].filter((item) => Boolean(item.url))
  }

  toJSON(): CommonAdaptativeImageItem {
    return { ...this }
  }
}

import type { IStoreLocatorRepository } from '@contexts/store-locator/domain/store-locator-repository.interface'
import type { BasicStore } from '@contexts/store-locator/domain/stores.interface'

export class GetAllStores {
  constructor(public readonly storeLocatorRepository: IStoreLocatorRepository) {}

  async execute(): Promise<BasicStore[]> {
    return this.storeLocatorRepository.fetchAllStores()
  }
}

import type { IShopState } from '@application/store/shop/interface'
import type { MutationTree } from '@utils/esmParser'
import { shopTypes } from '@application/store/shop/constants'
import type { ICurrentStore } from '@domain/current-store/current-store.interface'

const shopMutations: MutationTree<IShopState> = {
  [shopTypes.SET_SHOP]: (state: IShopState, shop: ICurrentStore) => {
    state.current = shop
  }
}
export default shopMutations

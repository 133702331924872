import { type ComponentInternalInstance, getCurrentInstance } from 'vue'
import { useNuxtApp } from '#imports'
import type { NuxtApp } from '#app'

interface NuxtProxy {
  $_nuxtApp?: NuxtApp
  _router?: {
    app: {
      context: {
        $_nuxtApp?: NuxtApp
      }
    }
  }
}

export const useSsrNuxtApp = () => {
  const instance = getCurrentInstance() as ComponentInternalInstance | null

  if (instance) {
    const proxy = instance.proxy as unknown as NuxtProxy
    return (
      // eslint-disable-next-line no-underscore-dangle
      useNuxtApp() || proxy?.$_nuxtApp || proxy?._router?.app.context.$_nuxtApp
    )
  }
  return useNuxtApp()
}

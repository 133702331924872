/* 
  Certaines de nos librairies ne sont pas totalement compatibles avec les ES Module.
  Comme Nuxt 3 impose leurs utilisations, nous sommes contraint parfois de recourir à un parsing manuel des imports pour que cela fonctionne en SSR et SPA.

  Dans la majorité des cas, ce genre de soucis intervient en mode build, pas en mode dev.

  À certains endroits du code, nous sommes aussi obligés de bien distinguer les parties uniquements accessibles en mode serveur et en mode client.
  Exemple pour la partie Auth0 qui ne peut s'exécuter que client side : src/contexts/auth/infrastructure/authentication.repository.ts
*/

import * as _Contentful from 'contentful'
// eslint-disable-next-line import/no-named-default
import { default as _axios } from 'axios'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as _vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type * as VuexType from 'vuex/types/index.d'

import _sdkStoreLocator from '@invivoretail/module-store-locator-sdk'
import _sdkUsers from '@invivoretail/module-users-sdk'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex/types/index.d'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore : incompri par TS car c'est un code spécifique pour le build de Nuxt
export const axios = _axios?.default?.default || _axios?.default || _axios

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore : incompri par TS car c'est un code spécifique pour le build de Nuxt
// export const Contentful = (_Contentful?.default || _Contentful) as _Contentful

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore : incompri par TS car c'est un code spécifique pour le build de Nuxt
export const sdkStoreLocator = _sdkStoreLocator.default || _sdkStoreLocator

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore : incompri par TS car c'est un code spécifique pour le build de Nuxt
export const sdkUsers = _sdkUsers.default || _sdkUsers

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore : incompri par TS car c'est un code spécifique pour le build de Nuxt
export const Contentful = (_Contentful?.default || _Contentful) as _Contentful

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const { createStore } = _vuex as VuexType

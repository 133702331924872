import { computed, type ComputedRef, type Ref, useNuxtApp } from '#imports'
import { metaConfig } from '@application/composables/metas/meta-config'
import { useLocation } from '@application/composables/location'
import { Utils } from '@application/composables/utils'
import type { Thing, WithContext } from 'schema-dts'
import type { MetaObject } from '@nuxt/schema'

export interface IMetaHead {
  title?: Ref<string | null>
  suffixTitle?: Ref<string>
  description?: Ref<string | null>
  thumbnail?: Ref<string | null>
  canonical?: Ref<string | null>
  scripts?: Ref<unknown[]>
  jsonLd?: ComputedRef<WithContext<Thing>[]>
  links?: ComputedRef<unknown[]>
}

const getTitle = (titlePage: string | null, brandName: string | null, suffixTitle?: string): string => {
  const title = titlePage || metaConfig.title
  const suffix = Utils.capitalize(suffixTitle || brandName || '')
  return `${title} - ${suffix}`
}

const getMetas = (title: string | null, description: string | null, thumbnail: string | null): unknown[] => [
  {
    hid: 'description',
    name: 'description',
    content: description || metaConfig.description
  },
  {
    hid: 'og:title',
    property: 'og:title',
    content: title || metaConfig.title
  },
  {
    hid: 'og:description',
    property: 'og:description',
    content: description || metaConfig.description
  },
  {
    hid: 'og:image',
    property: 'og:image',
    content: thumbnail || metaConfig.image
  },
  {
    hid: 'twitter:card',
    name: 'twitter:card',
    content: 'summary'
  },
  {
    hid: 'twitter:title',
    name: 'twitter:title',
    content: title || metaConfig.title
  },
  {
    hid: 'twitter:description',
    name: 'twitter:description',
    content: description || metaConfig.description
  },
  {
    hid: 'twitter:image',
    name: 'twitter:image',
    content: thumbnail || metaConfig.image
  }
]

const getLinks = (canonical: string | null, initialLinks?: ComputedRef<unknown[]>): unknown[] => {
  const links: unknown[] = initialLinks?.value?.length ? initialLinks.value : []
  if (canonical) {
    links.push({ rel: 'canonical', href: canonical })
  }
  return links
}

export const useMetaHead = (data: IMetaHead) => {
  const { $i18n: i18n } = useNuxtApp()
  const { getPageLocation } = useLocation()
  const title = computed(() =>
    getTitle(data.title?.value || null, i18n.t('common.brand') as string, data.suffixTitle?.value)
  )
  const meta = computed(() => getMetas(title.value, data.description?.value || null, data.thumbnail?.value || null))
  const link = computed(() => getLinks(data.canonical?.value || getPageLocation(), data.links))
  const script: ComputedRef<unknown[]> = computed(() => {
    const externalScripts = data.scripts?.value || []
    const jsonLd = (data.jsonLd?.value || []).map((json: WithContext<Thing>) => ({
      hid: 'json-ld',
      type: 'application/ld+json',
      json
    }))
    return [...externalScripts, ...jsonLd]
  })

  const metaInfo = computed(
    () =>
      ({
        title: title.value,
        meta: meta.value,
        link: link.value,
        script: script.value
      } as MetaObject)
  )
  return { metaInfo }
}

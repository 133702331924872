import type { GetterTree } from '@utils/esmParser'
import type { IRootState } from '../state.interface'
import type { ICartState } from './interface'

const cartGetter: GetterTree<ICartState, IRootState> = {
  getCart(cartState: ICartState): ICartState {
    return cartState
  }
}

export default cartGetter

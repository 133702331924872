import { useConfig } from '@/application/composables/config'
import { OffersClient } from '@invivoretail/module-offers-sdk'
import { OfferDataSource } from './offer.datasource'

const { getOffersApiUrl, getEnv } = useConfig()

export const offersClient = new OffersClient({
  baseUrl: getOffersApiUrl(),
  callerId: `enseignes-${getEnv('BRAND_NAME')}`
})
export const offerDataSource = new OfferDataSource(offersClient)
